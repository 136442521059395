import axios from 'axios';

// HEADERS
export const HEADERS_LANGUAGE = 'Accept-Language';

// DOMINIO
export const API_URL = 'https://api.maegmant.es/'; // https://api.staging.maegmant.es/';
// LOGIN
export const API_URL_LOGIN = `${API_URL}auth/login/`;

// USERS
export const API_URL_USERS = `${API_URL}users`;

// ORDERS
export const API_URL_ORDERS = `${API_URL}orders/`;
export const API_URL_ORDERS_STATES_SELET = `${API_URL_ORDERS}select/states/`;
export const API_URL_ORDERS_CITIES_SELET = `${API_URL_ORDERS}select/cities/`;
export const API_URL_ORDER_ID = `${API_URL_ORDERS}{{orderId}}/`;
export const API_URL_ORDER_ID_STATUS = `${API_URL_ORDER_ID}orderStatus`;
export const API_URL_ORDER_ID_DELIVERY_EVIDENCE = `${API_URL_ORDER_ID}delivery-evidence/`;
export const API_URL_ORDER_REFERENCE = `${API_URL_ORDERS}reference/{{reference}}`;
export const API_URL_ORDER_TRACKING_REFERENCE = `${API_URL_ORDERS}tracking/{{reference}}`;
//ORDER STATUS
export const API_URL_ORDER_ID_ORDER_STATUS = `${API_URL_ORDER_ID}order-status/`;
export const API_URL_ORDER_ID_ORDER_STATUS_ID = `${API_URL_ORDER_ID_ORDER_STATUS}{{orderStatusId}}/`;
export const API_URL_ORDER_ID_ORDER_STATUS_ID_EVIDENCE = `${API_URL_ORDER_ID_ORDER_STATUS_ID}evidence/`;

// ORDER EXCEPTIONS
export const API_URL_ORDERS_EXCEPTIONS = `${API_URL}orders-exceptions/`;
export const API_URL_ORDERS_EXCEPTIONS_SELECT = `${API_URL_ORDERS_EXCEPTIONS}select/`;
export const API_URL_ORDERS_EXCEPTIONS_ID = `${API_URL_ORDERS_EXCEPTIONS}{{orderExceptionId}}/`;
export const API_URL_ORDERS_EXCEPTIONS_ID_ASSING_EMPLOYEE = `${API_URL_ORDERS_EXCEPTIONS_ID}assing/{{employeeId}}/`;
export const API_URL_ORDERS_EXCEPTIONS_CANCEL = `${API_URL_ORDERS_EXCEPTIONS_ID}cancel/`;
export const API_URL_ORDERS_EXCEPTIONS_ANSWER = `${API_URL_ORDERS_EXCEPTIONS_ID}answer/`;
export const API_URL_ORDERS_EXCEPTIONS_CONCLUSION = `${API_URL_ORDERS_EXCEPTIONS_ID}conclusion/`;

// MANIFESTS
export const API_URL_MANIFESTS = `${API_URL}manifests/`;
export const API_URL_MANIFESTS_UPLOAD = `${API_URL_MANIFESTS}upload/`;
export const API_URL_MANIFESTS_ID = `${API_URL_MANIFESTS}{{manifestId}}/`;

// BOXES
export const API_URL_BOXES = `${API_URL}boxes/`;
export const API_URL_BOXES_CODE = `${API_URL_BOXES}code/{{code}}/`;
export const API_URL_BOXES_CODE_CHANGE_ORDER_STATUS = `${API_URL_BOXES_CODE}orderStatusCode/{{orderStatusCode}}`;

// LIST
export const API_URL_ORDER_STATUS_SELECT_TREE = `${API_URL_ORDERS}list/status`;

// FORECAST
export const API_URL_ORDER_FORECAST = `${API_URL_ORDERS}forecast/`;
export const API_URL_ORDER_FORECAST_EXPORT = `${API_URL_ORDER_FORECAST}export/`;

// MRW
export const API_URL_INTERATION_MRW = `${API_URL}third-party-integrations/mrw/`;
export const API_URL_INTERATION_MRW_ORDER = `${API_URL_INTERATION_MRW}orders/`;
export const API_URL_INTERATION_MRW_CREATE_ORDER = `${API_URL_INTERATION_MRW_ORDER}{{orderId}}/`;
export const API_URL_INTERATION_MRW_CREATE_ORDER_REFERENCE = `${API_URL_INTERATION_MRW_ORDER}reference/{{reference}}/`;
export const API_URL_INTERATION_MRW_GET_LABEL_REFERENCE = `${API_URL_INTERATION_MRW_ORDER}reference/{{reference}}/label/`;
export const API_URL_INTERATION_MRW_GET_LABELS = `${API_URL_INTERATION_MRW_ORDER}labels/`;

// ROUTES
export const API_URL_ROUTES = `${API_URL_ORDERS}routes/`;
export const API_URL_ROUTES_EXCEL = `${API_URL_ROUTES}excel/`;
export const API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED = `${API_URL_ROUTES}employee/{{employeeId}}/`;
export const API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED_EXPORT = `${API_URL_ROUTES_EMPLOYEE_ID_ORDERS_ASSIGNED}excel/`;

// FOLLOWING
export const API_URL_FOLLOWING = `${API_URL_ORDERS}following/`;
export const API_URL_FOLLOWING_EXCEL = `${API_URL_FOLLOWING}excel/`;

// EMPLOYEE
export const API_URL_EMPLOYEES = `${API_URL}employees/`;
export const API_URL_EMPLOYEES_SELECT = `${API_URL_EMPLOYEES}select/`;
export const API_URL_EMPLOYEES_EMPLOYEE_ID_ORDERS_ASSIGNED = `${API_URL_EMPLOYEES}{{employeeId}}/orders/assigned/`;
export const API_URL_EMPLOYEES_EMPLOYEE_ID_ORDERS_ASSIGNED_EXPORT = `${API_URL_EMPLOYEES_EMPLOYEE_ID_ORDERS_ASSIGNED}excel/`;
export const API_URL_EMPLOYEES_REPORTING = `${API_URL_EMPLOYEES}reporting/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY = `${API_URL_EMPLOYEES_REPORTING}productivity/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_EXPORT = `${API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY}excel/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DAY = `${API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY}day/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DAY_EXPORT = `${API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DAY}excel/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DETAILS = `${API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY}details/`;
export const API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DETAILS_EXPORT = `${API_URL_EMPLOYEES_REPORTING_PRODUCTIVITY_DETAILS}excel/`;

// FLEET
export const API_URL_FLEETS = `${API_URL}fleets/`;
export const API_URL_FLEETS_SELECT = `${API_URL_FLEETS}select`;
export const API_URL_FLEETS_CITIES_SELECT = `${API_URL_FLEETS}cities/select`;
export const API_URL_FLEETS_STATES_SELECT = `${API_URL_FLEETS}states/select`;

// OPERATOR
export const API_URL_OPERATORS = `${API_URL}operators/`;
export const API_URL_OPERATORS_SELECT = `${API_URL_OPERATORS}select`;

// Bussiness
export const API_URL_BUSINESS = `${API_URL}businesses/`;
export const API_URL_BUSINESS_SELECT = `${API_URL_BUSINESS}select`;

// Clients
export const API_URL_CLIENTS = `${API_URL}clients/`;
export const API_URL_CLIENTS_SELECT = `${API_URL_CLIENTS}select`;

// PDF
export const API_URL_LABELS_PDF = `${API_URL_ORDERS}shipping-labels/`;
export const API_URL_LABEL_PDF = `${API_URL_ORDERS}{{orderId}}/shipping-label/`;
export const API_URL_PODS_PDF = `${API_URL_ORDERS}pods/`;
export const API_URL_POD_PDF = `${API_URL_ORDERS}{{orderId}}/pod/`;

// PICKING
export const API_URL_ORDER_STATUS_CHANGE = `${API_URL_ORDERS}orderStatusChange`;
export const API_URL_PICKING_OPTIONS = `${API_URL_ORDERS}list/pickingOptions`;

const api = axios.create({
	baseURL: API_URL,
});

api.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('accessToken');
		const language = localStorage.getItem('i18nextLng');

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		config.headers['Accept-Language'] = language || 'es';

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export const get = (url, params, config = {}) =>
	api.get(url, { ...config, params });
export const post = (url, body, config = {}) => api.post(url, body, config);
export const put = (url, body, config = {}) => api.put(url, body, config);
export const del = (url, config = {}) => api.delete(url, config);
export const patch = (url, body, config = {}) => api.patch(url, body, config);

// TRACKING
export const fetchData = async (reference, config = {}) => {
	try {
		if (!reference) {
			throw new Error('La referencia no está definida');
		}
		const url = API_URL_ORDER_TRACKING_REFERENCE.replace(
			'{{reference}}',
			reference
		);
		const response = await get(url, config);
		return response.data;
	} catch (error) {
		console.error('Error fetching data', error);
		throw new Error('Failed to fetch data');
	}
};
