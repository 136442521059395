import React, { useState } from 'react';
import { Button, Tag, Timeline, Tooltip } from 'antd';
import { ClockCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { API_URL_ORDER_ID_ORDER_STATUS_ID_EVIDENCE, get } from '../../../App/Api';
export function OrderStatusTracking({ detailStatus }) {
	const { t } = useTranslation();
	const [imageOrderStatusEvidence, setImageOrderStatusEvidence] = useState(null);
	const [activeButton, setActiveButton] = useState(null);

	const fetchImage = async (orderStatus, index) => {
		try {
			setActiveButton(null);
			setImageOrderStatusEvidence(null);
			if (index === activeButton) {
				return;
			}
			setActiveButton(index);
			const response = await get(
				API_URL_ORDER_ID_ORDER_STATUS_ID_EVIDENCE.replace(
					'{{orderId}}',
					orderStatus.orderId
				).replace(
					'{{orderStatusId}}',
					orderStatus.orderStatusId
				),
				{},
				{
					responseType: 'blob',
				}
			);
			const imageUrl = URL.createObjectURL(response.data);
			setImageOrderStatusEvidence(imageUrl);
		} catch (error) {
			console.error('Error fetching the image:', error);
		}
	};


	const items = detailStatus.map((event, index) => ({
		label: event.dateTime,
		key: index,
		children: (
			<div>
				{event.fleetName}-{event.employeeName}:
				<Tag color="default">
					{event.orderStatus === event.orderStatusParent ? (
						<>{event.orderStatusParent}</>
					) : (
						<>
							{event.orderStatusParent}-{event.orderStatus}
						</>
					)}
				</Tag>
				{event.evidence && (
					<Button
						type={activeButton === index ? 'primary' : 'default'}
						shape="circle"
						icon={activeButton === index ? <EyeOutlined /> : <EyeInvisibleOutlined />}
						onClick={() => fetchImage(event, index)} />
				)}
				{event.note && (
					<>
						{event.note}
					</>
				)}
			</div>
		),
	}));

	return <>
		<Timeline mode="left" items={items} />
		{imageOrderStatusEvidence && (
			<img
				src={imageOrderStatusEvidence}
				alt={t('orders_status.evidence')}
				style={{ width: '200px', height: 'auto', margin: 'auto' }}
			/>
		)}
	</>;
}
